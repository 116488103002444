<template>
  <div>
    <b-table
      v-if="relatedInvoices.length"
      ref="refRelatedInvoicesTable"
      class="position-relative event_list min-height-200 shadow-table"
      :items="relatedInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Role -->
      <template #cell(Trx_No)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'Invoice')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'customers-invoices-show', params: { id: data.item.Trx_Id } }"
          >
            {{ data.item.Trx_No }}
            <br>
            <b-button
              v-if="canViewThisAction('show', 'File')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'customers-files-show', params: { id: data.item.File_Id } }"
            >
              {{ data.item.File_No }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.File_No }}
            </b-button>
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.stringID }}
            <span class="font_normal">fr.</span>
            <b-button
              v-if="canViewThisAction('show', 'File')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'customers-files-show', params: { id: data.item.File_Id } }"
            >
              {{ data.item.File_No }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.File_No }}
            </b-button>
          </b-button>
        </div>
      </template>

      <template #cell(fileType)="data">
        <div class="text-nowrap">
          <span class="">{{ fileType }}</span>
          <br>
          <span
            v-if="data.item.Lot_Location"
            class=""
          >
            {{ data.item.Lot_Location }}
          </span>
        </div>
      </template>

      <template #cell(Entity_Name)="data">
        <div
          class="text-nowrap"
        >
          <span class="">{{ data.item.Entity_Name }}</span>
          <br>
          <span
            v-if="data.item.Entity_NRIC"
            class=""
          >
            {{ data.item.Entity_NRIC[0] + data.item.Entity_NRIC.slice(1).replace(/.(?=.{4,}$)/g, '*') }}
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(invoiceStatus)="data">
        <b-badge
          :variant="`light-${resolveInvoiceStatusVariant(data.item.invoiceStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.invoiceStatus }}
        </b-badge>
      </template>

      <template #cell(paymentStatus)="data">
        <b-badge
          :variant="`light-${resolvePaymentStatusVariant(data.item.paymentStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.paymentStatus }}
        </b-badge>
      </template>

      <template #cell(Created_By)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.Created_By }}</span>
          <br>
          <span class="">{{ dateFormat(data.item.Created_On) }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'Invoice')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="canViewThisAction('show', 'Invoice')"
            :to="{ name: 'customers-invoices-show', params: { id: data.item.Trx_Id } }"
          >
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No invoices found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    relatedInvoices: {
      type: [Array, null],
      default: () => [],
    },
    fileType: {
      type: [String, null],
      default: '',
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'Trx_No',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'Trx_No',
          label: 'Invoice & File No.',
          sortable: false,
        },
        {
          key: 'fileType',
          label: 'Product Description',
          sortable: false,
        },
        {
          key: 'Entity_Name',
          label: 'customer',
          sortable: false,
        },
        {
          key: 'invoiceStatus',
          label: 'invoice status',
          sortable: false,
        },
        {
          key: 'Created_By',
          label: 'Created By',
          sortable: true,
        },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveInvoiceStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    resolvePaymentStatusVariant(status) {
      if (status === 'Fully Paid') return 'success'
      if (status === 'Ongoing Installment') return 'info'
      if (status === 'Pending Payment') return 'warning'
      if (status === 'Reactivation Fee') return 'danger'
      return 'secondary'
    },
  },
}
</script>
