<template>
  <b-card class="dashboard__tab-content-card">
    <b-row class="match-height">
      <b-col :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.fileType)) ? 4 : 6">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            File Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    File No.
                  </b-th>
                  <b-td>{{ fileInfo.File_No ? fileInfo.File_No : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Booking Date
                  </b-th>
                  <b-td>{{ fileInfo.Booking_Date ? fileInfo.Booking_Date : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Confirm Date
                  </b-th>
                  <b-td>{{ fileInfo.Confirm_Date ? fileInfo.Confirm_Date : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Confirmed By
                  </b-th>
                  <b-td>{{ fileInfo.Confirm_By ? fileInfo.Confirm_By : '-' }}</b-td>
                </b-tr>
                <b-tr v-if="['Niche'].includes(fileInfo.fileType)">
                  <b-th width="230px">
                    Exercise Date
                  </b-th>
                  <b-td>{{ fileInfo.Exercise_Date ? fileInfo.Exercise_Date : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Product Description
                  </b-th>
                  <b-td>{{ fileInfo.fileType ? fileInfo.fileType : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Payment Method
                  </b-th>
                  <b-td>{{ fileInfo.paymentMethod ? fileInfo.paymentMethod : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Customer Type
                  </b-th>
                  <b-td class="text-capitalize">
                    {{ fileInfo.customerType ? fileInfo.customerType.toLowerCase() : '-' }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Need Type
                  </b-th>
                  <b-td>{{ fileInfo.needType ? fileInfo.needType : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Bury At NV
                  </b-th>
                  <b-td>{{ fileInfo.buryAtNv ? fileInfo.buryAtNv : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Purchase Order No.
                  </b-th>
                  <b-td>{{ fileInfo.poNo ? fileInfo.poNo : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="9"
                  md="9"
                  sm="9"
                  lg="10"
                >
                  <p>{{ fileInfo.fileRemarks ? fileInfo.fileRemarks : '' }}</p>
                </b-col>
                <b-col
                  class="content-header-left text-right"
                  cols="3"
                  md="3"
                  sm="3"
                  lg="2"
                >
                  <b-button
                    v-if="canViewThisAction('update', 'File')"
                    v-b-tooltip.hover.top="'Edit Remarks'"
                    variant="flat-primary"
                    class="edit_remark_button"
                    @click="editRemarks"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="EditIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.fileType)) ? 4 : 6">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Purchaser Details
          </b-card-title>
          <b-card-body>
            <b-tabs
              v-if="fileInfo.purchasers && fileInfo.purchasers.length"
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-for="(purchaser, key) in fileInfo.purchasers"
                :key="'purchaser' + key"
                :title="'PCR #' + (key + 1)"
              >
                <b-card-text>
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Purchaser Name
                        </b-th>
                        <b-td>{{ purchaser.Name ? purchaser.Name : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          NRIC
                        </b-th>
                        <b-td>{{ purchaser.NRIC ? purchaser.NRIC : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Date of Birth
                        </b-th>
                        <b-td>{{ purchaser.Entity_DOB ? dateFormat(purchaser.Entity_DOB) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Sex
                        </b-th>
                        <b-td>{{ purchaser.Sex ? purchaser.Sex : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Race
                        </b-th>
                        <b-td>{{ purchaser.Race ? purchaser.Race : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nationality
                        </b-th>
                        <b-td>{{ purchaser.Nationality ? purchaser.Nationality : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Mobile No.
                        </b-th>
                        <b-td>{{ purchaser.Per_Tel_No_Hp ? modifyContact(purchaser.Per_Tel_No_Hp) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Email Address
                        </b-th>
                        <b-td class="">
                          {{ purchaser.Per_Email ? purchaser.Per_Email.toLowerCase() : '-' }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Residential Address
                        </b-th>
                        <b-td v-if="!purchaser.Per_Addr1 && !purchaser.Per_Addr2 && !purchaser.Per_Addr3">
                          -
                        </b-td>
                        <b-td v-else>
                          {{ purchaser.Per_Addr1 ? (purchaser.Per_Addr1 + ', ') : '' }}
                          {{ purchaser.Per_Addr2 ? (purchaser.Per_Addr2 + ', ') : '' }}
                          {{ purchaser.Per_Addr3 ? purchaser.Per_Addr3 : '' }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          SG-Nirvana User
                        </b-th>
                        <b-td>Yes</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.fileType))"
        :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.fileType)) ? 4 : 6"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Lot Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Zone No.
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Zone_No ? fileInfo.relatedLot.Zone_No : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Lot No.
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Lot_No ? fileInfo.relatedLot.Lot_No : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Lot Type
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Lot_Type ? fileInfo.relatedLot.Lot_Type : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Bury Capacity
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Bury_Capacity ? fileInfo.relatedLot.Bury_Capacity : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Area Length
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Area_Length ? fileInfo.relatedLot.Area_Length : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.fileType)">
                  <b-th width="230px">
                    Direction
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Direction ? fileInfo.relatedLot.Direction : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.fileType)">
                  <b-th width="230px">
                    Side
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Side ? fileInfo.relatedLot.Side : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Level No.
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Level_No ? fileInfo.relatedLot.Level_No : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.fileType)">
                  <b-th width="230px">
                    Section No.
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Section ? fileInfo.relatedLot.Section : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Land Category
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Lot_Category ? fileInfo.relatedLot.Lot_Category : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Interment Date
                  </b-th>
                  <b-td>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Eff_Interment_Date ? dateFormat(fileInfo.relatedLot.Eff_Interment_Date) : '-') : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <p>{{ fileInfo.relatedLot ? (fileInfo.relatedLot.Remark ? fileInfo.relatedLot.Remark : '-') : '-' }}</p>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="fileInfo && fileInfo.fileType != 'Little Buddha'"
      class="match-height"
    >
      <b-col
        v-if="fileInfo.fileType != 'Urn'"
        md="4"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Certificate Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Cert No
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Cert_No ? fileInfo.certificate.Cert_No : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Batch No.
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Batch_No ? fileInfo.certificate.Batch_No : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Cert_Date ? dateFormat(fileInfo.certificate.Cert_Date) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Send Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Send_Date ? dateFormat(fileInfo.certificate.Send_Date) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Receive Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Receive_Date ? dateFormat(fileInfo.certificate.Receive_Date) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Issue Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Issue_Date ? dateFormatWithFormatParam(fileInfo.certificate.Issue_Date, 'DD/MM/YYYY, hh:mm:ssa') : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Delivery Method
                  </b-th>
                  <b-td>
                    <span>
                      {{ fileInfo.certificate ? (fileInfo.certificate.Delivery_Method ? fileInfo.certificate.Delivery_Method : '-') : '-' }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Delivery Status
                  </b-th>
                  <b-td>
                    <span class="text-success weight-500">
                      {{ fileInfo.certificate ? (fileInfo.certificate.Delivery_Status ? fileInfo.certificate.Delivery_Status : '-') : '-' }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Transfer Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Cert_Transfer_date ? dateFormat(fileInfo.certificate.Cert_Transfer_date) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Duplicate Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.Cert_Duplicate_Date ? dateFormat(fileInfo.certificate.Cert_Duplicate_Date) : '-') : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <p>{{ fileInfo.certificate ? (fileInfo.certificate.Cert_Remark ? fileInfo.certificate.Cert_Remark : '-') : '-' }}</p>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col :md="fileInfo.fileType == 'Urn' ? 12 : 8">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Related Nominees
          </b-card-title>
          <b-card-body>
            <b-tabs
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-if="!['Urn', 'Pedestal', 'Little Buddha'].includes(fileInfo.fileType)"
                :title="'Intended User ' + (fileInfo.intendedUsers ? (`(${fileInfo.intendedUsers.length})`) : '(0)')"
              >
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.intendedUsers && fileInfo.intendedUsers.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.intendedUsers.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Intended User #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <span class="text-primary weight-600">
                            {{ iu.Name }}
                            <b-badge
                              v-if="iu.isPurchaser"
                              variant="light-info"
                              class="ml-50"
                            >
                              {{ iu.purchaserNo }}
                            </b-badge>
                          </span>
                          <br>
                          <span>{{ iu.NRIC }}</span>
                          <br>
                          <span>{{ iu.Relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Per_Tel_No_Hp || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Entity_DOB ? dateFormat(iu.Entity_DOB) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span v-if="!iu.Per_Addr1 && !iu.Per_Addr2 && !iu.Per_Addr3">
                              -
                            </span>
                            <span v-else>
                              {{ iu.Per_Addr1 ? (iu.Per_Addr1 + ', ') : '' }}
                              {{ iu.Per_Addr2 ? (iu.Per_Addr2 + ', ') : '' }}
                              {{ iu.Per_Addr3 ? (iu.Per_Addr3 + ', ') : '' }}
                              {{ iu.Per_Country ? (iu.Per_Country + ' ') : '' }}
                              {{ iu.Per_Postcode ? iu.Per_Postcode : '' }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab :title="'Next of Kin ' + (fileInfo.nextOfKins ? (`(${fileInfo.nextOfKins.length})`) : '(0)')">
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.nextOfKins && fileInfo.nextOfKins.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.nextOfKins.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Next of Kin #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <span class="text-primary weight-600">
                            {{ iu.Name }}
                            <b-badge
                              v-if="iu.isPurchaser"
                              variant="light-info"
                              class="ml-50"
                            >
                              {{ iu.purchaserNo }}
                            </b-badge>
                          </span>
                          <br>
                          <span>{{ iu.NRIC }}</span>
                          <br>
                          <span>{{ iu.Relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Per_Tel_No_Hp || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Entity_DOB ? dateFormat(iu.Entity_DOB) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span v-if="!iu.Per_Addr1 && !iu.Per_Addr2 && !iu.Per_Addr3">
                              -
                            </span>
                            <span v-else>
                              {{ iu.Per_Addr1 ? (iu.Per_Addr1 + ', ') : '' }}
                              {{ iu.Per_Addr2 ? (iu.Per_Addr2 + ', ') : '' }}
                              {{ iu.Per_Addr3 ? (iu.Per_Addr3 + ', ') : '' }}
                              {{ iu.Per_Country ? (iu.Per_Country + ' ') : '' }}
                              {{ iu.Per_Postcode ? iu.Per_Postcode : '' }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab :title="'Addressee ' + (fileInfo.addressees ? (`(${fileInfo.addressees.length})`) : '(0)')">
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.addressees && fileInfo.addressees.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.addressees.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Addressee #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <span class="text-primary weight-600">
                            {{ iu.Name }}
                            <b-badge
                              v-if="iu.isPurchaser"
                              variant="light-info"
                              class="ml-50"
                            >
                              {{ iu.purchaserNo }}
                            </b-badge>
                          </span>
                          <br>
                          <span>{{ iu.NRIC }}</span>
                          <br>
                          <span>{{ iu.Relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Per_Tel_No_Hp || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.Entity_DOB ? dateFormat(iu.Entity_DOB) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span v-if="!iu.Per_Addr1 && !iu.Per_Addr2 && !iu.Per_Addr3">
                              -
                            </span>
                            <span v-else>
                              {{ iu.Per_Addr1 ? (iu.Per_Addr1 + ', ') : '' }}
                              {{ iu.Per_Addr2 ? (iu.Per_Addr2 + ', ') : '' }}
                              {{ iu.Per_Addr3 ? (iu.Per_Addr3 + ', ') : '' }}
                              {{ iu.Per_Country ? (iu.Per_Country + ' ') : '' }}
                              {{ iu.Per_Postcode ? iu.Per_Postcode : '' }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <template #tabs-end>
                <div class="text-right ml-auto vendor-detail-more-icon view-more-icon">
                  <b-button
                    variant="link"
                    :to="{ name: 'customers-files-nominees', params: { id: $route.params.id } }"
                  >
                    View more <feather-icon icon="ArrowRightIcon" />
                  </b-button>
                </div>
              </template>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="match-height"
    >
      <b-col
        md="6"
      >
        <b-card
          title="Related Deceased Data (TBC)"
          class="has-padding"
        >
          <div class="empty_block">
            <h4>No Data Yet</h4>
            <span class="align-middle">Item will show once the data is entered</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Agent Details
          </b-card-title>
          <b-card-body>
            <b-tabs
              v-if="fileInfo.agents && fileInfo.agents.length > 1"
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-for="(agent, key) in fileInfo.agents"
                :key="'agent' + key"
                :title="agent.name"
              >
                <b-card-text>
                  <div class="d-flex gap-5">
                    <div>
                      <b-avatar
                        v-if="agent.image"
                        :src="agent.image"
                        size="150px"
                      />
                      <b-avatar
                        v-else
                        :text="resolveAcronymName(agent.name)"
                        size="150px"
                      />
                    </div>
                    <div>
                      <b-badge
                        variant="light-info"
                      >
                        {{ agent.agentType }}
                      </b-badge>
                      <br>
                      <span>
                        <feather-icon
                          icon="UserIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span class="text-primary weight-600">
                          {{ agent.name || '-' }} ({{ agent.code || '-' }})
                        </span>
                      </span>
                      <br>
                      <span>
                        <feather-icon
                          icon="TabletIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span>
                          {{ agent.contact || '-' }}
                        </span>
                      </span>
                      <p class="agency-text">
                        <span class="text-bold-black">Agency</span>
                        <span class="ml-1">{{ agent.agencyName || '-' }} ({{ agent.agencyCode || '-' }}) - {{ agent.branch || '-' }}</span>
                      </p>
                    </div>
                  </div>
                  <hr>
                  <p class="agency-text weight-500">
                    Other files under this agent for main purchaser
                  </p>
                  <span
                    v-for="(file, index) in agent.files"
                    :key="'agent-file' + index"
                    class="mr-50"
                  >
                    <b-button
                      :id="'tooltip-0-' + index"
                      variant="primary"
                    >
                      {{ file.fileNo }}
                    </b-button>
                    <b-tooltip
                      :target="'tooltip-0-' + index"
                      triggers="hover"
                      no-fade
                    >
                      <span>{{ file.fileType }}</span>
                      <br>
                      <span>{{ file.location }}</span>
                    </b-tooltip>
                  </span>
                </b-card-text>
              </b-tab>
            </b-tabs>
            <b-card-text v-else-if="fileInfo.agents && fileInfo.agents.length">
              <div class="d-flex gap-5">
                <div>
                  <b-avatar
                    v-if="fileInfo.agents[0].image"
                    :src="fileInfo.agents[0].image"
                    size="150px"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(fileInfo.agents[0].name)"
                    size="150px"
                  />
                </div>
                <div>
                  <b-badge
                    variant="light-info"
                  >
                    {{ fileInfo.agents[0].agentType }}
                  </b-badge>
                  <br>
                  <span>
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                      class="mr-25 iu-icon"
                    />
                    <span class="text-primary weight-600">
                      {{ fileInfo.agents[0].name || '-' }} ({{ fileInfo.agents[0].code || '-' }})
                    </span>
                  </span>
                  <br>
                  <span>
                    <feather-icon
                      icon="TabletIcon"
                      size="22"
                      class="mr-25 iu-icon"
                    />
                    <span>
                      {{ fileInfo.agents[0].contact || '-' }}
                    </span>
                  </span>
                  <p class="agency-text">
                    <span class="text-bold-black">Agency</span>
                    <span class="ml-1">{{ fileInfo.agents[0].agencyName || '-' }} ({{ fileInfo.agents[0].agencyCode || '-' }}) - {{ fileInfo.agents[0].branch || '-' }}</span>
                  </p>
                </div>
              </div>
              <hr>
              <p class="agency-text weight-500">
                Other files under this agent for main purchaser
              </p>
              <span
                v-for="(file, index) in fileInfo.agents[0].files"
                :key="'agent-file' + index"
                class="mr-50"
              >
                <b-button
                  :id="'tooltip-0-' + index"
                  variant="primary"
                >
                  {{ file.fileNo }}
                </b-button>
                <b-tooltip
                  :target="'tooltip-0-' + index"
                  triggers="hover"
                  no-fade
                >
                  <span>{{ file.fileType }}</span>
                  <br>
                  <span>{{ file.location }}</span>
                </b-tooltip>
              </span>
            </b-card-text>
            <div
              v-else
              class="empty_block"
            >
              <h4>No Data Yet</h4>
              <span class="align-middle">Item will show once the data is entered</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BRow, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BButton, VBTooltip, VBToggle,
  BTabs, BTab, BCardText, BDropdown, BDropdownItem, BBadge, BAvatar, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BCardText,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BTooltip,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    fileInfo: {
      type: [Object, null],
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
    canViewThisAction,
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    editUser(user, index) {
      this.$emit('emitIntendedUserEdit', user, index)
    },
    editRemarks() {
      this.$emit('emitRemarksEdit')
    },
    removeUser(user, index) {
      this.$emit('emitIntendedUserRemove', user, index)
    },
  },
}
</script>
