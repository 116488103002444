<template>
  <div>
    <b-card class="shadow-card">
      <b-table
        v-if="debitAllocation.length"
        ref="refBillingDNTable"
        class="position-relative event_list"
        :items="debitAllocation"
        responsive
        :fields="debitTableColumns"
        primary-key="id"
        :sort-by.sync="debitSortBy"
        show-empty
        no-sort-reset
        caption-top
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescDebit"
      >
        <template #table-caption>
          <span class="fs-15 weight-500 text-capitalize caption-text">Debit Allocation</span>
        </template>
        <template #cell(Allocated_Amount)="data">
          <div class="text-nowrap">
            <span class="">${{ numberFormat(data.item.Allocated_Amount) }}</span>
          </div>
        </template>
        <template #bottom-row="">
          <td
            class="bottom-row"
            colspan="6"
          >
            <span class="weight-500 fs-18">Total Amount {{ getTotalDebit() }}</span>
          </td>
        </template>
      </b-table>
      <div
        v-else
        class="empty_block"
      >
        <h4>No debit allocations found.</h4>
      </div>
    </b-card>
    <b-card class="shadow-card">
      <b-table
        v-if="creditAllocation.length"
        ref="refBillingDNTable"
        class="position-relative event_list"
        :items="creditAllocation"
        responsive
        :fields="creditTableColumns"
        primary-key="id"
        :sort-by.sync="creditSortBy"
        show-empty
        no-sort-reset
        caption-top
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescCredit"
      >
        <template #table-caption>
          <span class="fs-15 weight-500 text-capitalize caption-text">Credit Allocation</span>
        </template>
        <template #cell(Allocated_Amount)="data">
          <div class="text-nowrap">
            <span class="">${{ numberFormat(data.item.Allocated_Amount) }}</span>
          </div>
        </template>
        <template #bottom-row="">
          <td
            class="bottom-row"
            colspan="6"
          >
            <span class="weight-500 fs-18">Total Amount {{ getTotalCredit() }}</span>
          </td>
        </template>
      </b-table>
      <div
        v-else
        class="empty_block"
      >
        <h4>No credit allocations found.</h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BCard,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    debitAllocation: {
      type: [Array, null],
      default: () => [],
    },
    creditAllocation: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      debitSortBy: 'No',
      creditSortBy: 'No',
      sortDesc: false,
      isSortDirDescDebit: false,
      isSortDirDescCredit: false,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      debitTableColumns: [
        {
          key: 'DN_Trx_No',
          label: 'billing/DN TRX No.',
          sortable: true,
        },
        {
          key: 'CN_Trx_No',
          label: 'Receipt/CN trx no',
          sortable: true,
        },
        {
          key: 'CN_Type',
          label: 'receipt/CN Type',
          sortable: true,
        },
        {
          key: 'CN_Item',
          label: 'Receipt/CN Item',
          sortable: true,
        },
        {
          key: 'Allocated_Amount',
          label: 'allocated Amount',
          sortable: true,
        },
      ],
      creditTableColumns: [
        {
          key: 'CN_Trx_No',
          label: 'receipt/cN TRX No.',
          sortable: true,
        },
        {
          key: 'DN_Trx_No',
          label: 'billing/DN TRX No.',
          sortable: true,
        },
        {
          key: 'DN_Type',
          label: 'billing/dN Type',
          sortable: true,
        },
        {
          key: 'DN_Item',
          label: 'billing/dN Item',
          sortable: true,
        },
        {
          key: 'Allocated_Amount',
          label: 'allocated Amount',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    getTotalDebit() {
      const total = this.debitAllocation.reduce((n, { Allocated_Amount }) => n + Number(Allocated_Amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
    getTotalCredit() {
      const total = this.creditAllocation.reduce((n, { Allocated_Amount }) => n + Number(Allocated_Amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
