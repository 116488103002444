<template>
  <div>
    <b-table
      v-if="billingData.length"
      ref="refBillingDNTable"
      class="position-relative event_list shadow-table"
      :items="billingData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- <template #empty="">
        <b-row>
          <b-col>
            <div class="empty_block">
              <h4>No billings / debit notes found.</h4>
            </div>
          </b-col>
        </b-row>
      </template> -->
      <template #cell(Trx_No)="data">
        <div
          v-if="data.item.Type.toLowerCase() == 'invoice'"
          class="text-nowrap"
        >
          <b-button
            v-if="canViewThisAction('show', 'Invoice')"
            variant="link"
            class="p-0 weight-600"
            :to="{ name: 'customers-invoices-show', params: { id: data.item.Trx_Id } }"
          >
            {{ splitNewLine(data.item.Trx_No, 0) }}
          </b-button>
          <span
            v-else
            class=""
          >{{ splitNewLine(data.item.Trx_No, 0) }}</span>
        </div>
        <div
          v-else
          class="text-nowrap"
        >
          <span class="weight-600">{{ splitNewLine(data.item.Trx_No, 0) }}</span>
        </div>
      </template>
      <template #cell(Amount)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.Amount) }}</span>
        </div>
      </template>
      <template #cell(Created_On)="data">
        <div class="text-nowrap">
          <span class="">{{ splitNewLine(data.item.Created_On, 0) }}</span>
          <br>
          <span class="">{{ splitNewLine(data.item.Created_On, 1) }}</span>
        </div>
      </template>
      <template #bottom-row="">
        <td
          class="bottom-row"
          colspan="6"
        >
          <span class="weight-500 fs-18">Total Debit {{ getTotal() }}</span>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No billings / debit notes found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    billingData: {
      type: [Array, null],
      default: () => [],
    },
    ledgerSummary: {
      type: [Object, null],
      default: () => {},
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'No',
      sortDesc: false,
      isSortDirDesc: false,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'Trx_No',
          label: 'TRX No.',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'Item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'Amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'Remark',
          label: 'Remarks',
          sortable: true,
        },
        {
          key: 'Created_On',
          label: 'Created by',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      const separateLines = text.split(/\r?\n|\r|\n/g)
      if (separateLines[index]) {
        return separateLines[index]
      }

      return ''
    },
    getTotal() {
      const total = this.billingData.reduce((n, { Amount }) => n + Number(Amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
